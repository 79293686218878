#filters {
  margin-bottom: 1em;
}

#addendum {
  font-size: 1.1rem;
}

@media (max-width: 700px) {
  #control-panel {
    margin-top: -7rem;
  }

  #filters-wrapper.closed {
    display: none;
  }
}

#controls-toggle {
  text-align: right;
  font-size: 4rem;
  color: #fff;
}

#controls-toggle button {
  background: none;
  text-shadow: #000 0 0 2px;
  border: none;
  z-index: 10;
  text-align: center;
  width: 2em;
}
#controls-toggle button.closed {
  margin-bottom: 9rem;
}
#controls-toggle button:active {
  color: #999;
}

#controls-toggle button div.action {
  margin: 0;
  padding: 0;
  font-size: 2rem;
}
