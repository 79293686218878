.card-section-container {
    display: flex;
    flex-wrap: wrap;
}
@media (max-width: 700px) {
    .card-section-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
    }
}

@media (max-width: 500px) {
    .card-section-container {
        grid-template-columns: 1fr 1fr;
    }
}