body {
  background-image: url('../public/img/blackat-trim-flip.png'), url('../public/img/blackat-background-thin.png');
  background-repeat: no-repeat;
  background-size: 260px, auto;
  background-position: 2em 0, top left;
  font-size: 1.4rem;
}
h1,
h2,
h3,
h4,
h5,
select,
input, button {
  font-family: 'Rajdhani', sans-serif;
}
a {
  color: #ff6e00;
}

header {
  position: relative;
  padding-top: 2rem;
  padding-left: 4rem;
}

main {
  display: grid;
  grid-template-columns: 18em auto;
  column-gap: 3em;
  margin: 0 2em;
  padding-top: 100px;
  margin-bottom: -100px;
  position: relative;
  top: -100px;
}

main.loading {
  display: flex;
  justify-content: center;
}


#sides {
  width: 15em;
  display: flex;
  padding: 0 3rem;
}

#sides button {
  width: 50%;
}

footer {
  margin: 2rem;
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
}

#back-to-top {
  font-size: 1.6rem;
  text-align: center;
}
.mobile-only {
  display: none;
}

@media (max-width: 700px) {
  main {
    display: block;
  }
  .mobile-only {
    display: block;
  }
}