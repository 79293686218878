#title {
  color: #fff;
  text-shadow: #000 0 0 2px;
}

#title h1 {
  margin-bottom: 0;
}

#title #subtitle {
  position: relative;
  top: -0.5rem;
  font-weight: normal;
  font-size: 1.1rem;
}
