@font-face {
  font-family: 'netrunner';
  src: url('../../public/css/icons/netrunner.eot?cmdd12');
  src: url('../../public/css/icons/netrunner.eot?cmdd12#iefix') format('embedded-opentype'),
    url('../../public/css/icons/netrunner.ttf?cmdd12') format('truetype'),
    url('../../public/css/icons/netrunner.woff?cmdd12') format('woff'),
    url('../../public/css/icons/netrunner.svg?cmdd12#netrunner') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'netrunner' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-abr:before {
  content: '\e920';
}
.icon-subroutine:before {
  content: '\e900';
}
.icon-mu3:before {
  content: '\e901';
}
.icon-mu2:before {
  content: '\e902';
}
.icon-mu1:before {
  content: '\e903';
}
.icon-mu:before {
  content: '\e904';
}
.icon-trash:before {
  content: '\e905';
}
.icon-link-half-top:before {
  content: '\e906';
}
.icon-link-half-bottom:before {
  content: '\e907';
}
.icon-link:before {
  content: '\e908';
}
.icon-click:before {
  content: '\e909';
}
.icon-recurring-credit:before {
  content: '\e90a';
}
.icon-credit:before {
  content: '\e90b';
}
.icon-core:before {
  content: '\e914';
}
.icon-genesis:before {
  content: '\e911';
}
.icon-creation-and-control:before {
  content: '\e913';
}
.icon-spin:before {
  content: '\e90c';
}
.icon-honor-and-profit:before {
  content: '\e910';
}
.icon-lunar:before {
  content: '\e90f';
}
.icon-order-and-chaos:before {
  content: '\e90e';
}
.icon-sansan:before {
  content: '\e90d';
}
.icon-data-and-destiny:before {
  content: '\e912';
}
.icon-mumbad:before {
  content: '\e91f';
}
.icon-flashpoint:before {
  content: '\e921';
}
.icon-red-sand:before {
  content: '\e922';
}
.icon-terminal-directive:before {
  content: '\e923';
}
.icon-core2:before {
  content: '\e924';
}
.icon-kitara:before {
  content: '\e925';
}
.icon-reign-and-reverie:before {
  content: '\e926';
}
.icon-magnum-opus:before {
  content: '\e927';
}
.icon-sc19:before {
  content: '\e928';
}
.icon-ashes:before {
  content: '\e929';
}
.icon-magnum-opus-reprint:before {
  content: '\e92a';
}
.icon-interrupt:before {
  content: '\e92b';
}
.icon-salvaged-memories:before {
  content: '\e92c';
}
.icon-system-gateway:before {
  content: '\e92d';
}
.icon-system-update-2021:before {
  content: '\e92e';
}
.icon-borealis:before {
  content: '\e931';
}
.icon-liberation:before {
  content: '\e933';
}
.icon-anarch:before {
  content: '\e91a';
}
.icon-criminal:before {
  content: '\e919';
}
.icon-shaper:before {
  content: '\e91b';
}
.icon-jinteki:before {
  content: '\e916';
}
.icon-haas-bioroid:before {
  content: '\e918';
}
.icon-nbn:before {
  content: '\e915';
}
.icon-weyland-consortium:before {
  content: '\e917';
}
.icon-adam:before {
  content: '\e91d';
}
.icon-apex:before {
  content: '\e91e';
}
.icon-sunny-lebeau:before {
  content: '\e91c';
}
/* Neutral icon is the NSG Netrunner icon for both corp and runner */
.icon-neutral-corp:before {
  content: '\e92f';
}
.icon-neutral-runner:before {
  content: '\e92f';
}
.icon-agenda-points:before {
  content: '\e930';
}
