

#filters {
  margin-bottom: 1em;
}

#addendum {
  font-size: 1.1rem;
}

@media (max-width: 700px) {
  #control-panel {
    margin-top: -7rem;
  }

  #filters-wrapper.closed {
    display: none;
  }
}

#controls-toggle {
  text-align: right;
  color: #fff;
  font-size: 4rem;
}

#controls-toggle button {
  text-shadow: 0 0 2px #000;
  z-index: 10;
  text-align: center;
  background: none;
  border: none;
  width: 2em;
}

#controls-toggle button.closed {
  margin-bottom: 9rem;
}

#controls-toggle button:active {
  color: #999;
}

#controls-toggle button div.action {
  margin: 0;
  padding: 0;
  font-size: 2rem;
}

@font-face {
  font-family: netrunner;
  src: url("netrunner.6345f546.eot");
  src: url("netrunner.6345f546.eot#iefix") format("embedded-opentype"), url("netrunner.65845640.ttf") format("truetype"), url("netrunner.b3d34b9a.woff") format("woff"), url("netrunner.9e7f1ac4.svg#netrunner") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: netrunner !important;
}

.icon-abr:before {
  content: "";
}

.icon-subroutine:before {
  content: "";
}

.icon-mu3:before {
  content: "";
}

.icon-mu2:before {
  content: "";
}

.icon-mu1:before {
  content: "";
}

.icon-mu:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-link-half-top:before {
  content: "";
}

.icon-link-half-bottom:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-click:before {
  content: "";
}

.icon-recurring-credit:before {
  content: "";
}

.icon-credit:before {
  content: "";
}

.icon-core:before {
  content: "";
}

.icon-genesis:before {
  content: "";
}

.icon-creation-and-control:before {
  content: "";
}

.icon-spin:before {
  content: "";
}

.icon-honor-and-profit:before {
  content: "";
}

.icon-lunar:before {
  content: "";
}

.icon-order-and-chaos:before {
  content: "";
}

.icon-sansan:before {
  content: "";
}

.icon-data-and-destiny:before {
  content: "";
}

.icon-mumbad:before {
  content: "";
}

.icon-flashpoint:before {
  content: "";
}

.icon-red-sand:before {
  content: "";
}

.icon-terminal-directive:before {
  content: "";
}

.icon-core2:before {
  content: "";
}

.icon-kitara:before {
  content: "";
}

.icon-reign-and-reverie:before {
  content: "";
}

.icon-magnum-opus:before {
  content: "";
}

.icon-sc19:before {
  content: "";
}

.icon-ashes:before {
  content: "";
}

.icon-magnum-opus-reprint:before {
  content: "";
}

.icon-interrupt:before {
  content: "";
}

.icon-salvaged-memories:before {
  content: "";
}

.icon-system-gateway:before {
  content: "";
}

.icon-system-update-2021:before {
  content: "";
}

.icon-borealis:before {
  content: "";
}

.icon-liberation:before {
  content: "";
}

.icon-anarch:before {
  content: "";
}

.icon-criminal:before {
  content: "";
}

.icon-shaper:before {
  content: "";
}

.icon-jinteki:before {
  content: "";
}

.icon-haas-bioroid:before {
  content: "";
}

.icon-nbn:before {
  content: "";
}

.icon-weyland-consortium:before {
  content: "";
}

.icon-adam:before {
  content: "";
}

.icon-apex:before {
  content: "";
}

.icon-sunny-lebeau:before {
  content: "";
}

.icon-neutral-corp:before, .icon-neutral-runner:before {
  content: "";
}

.icon-agenda-points:before {
  content: "";
}

.side-button {
  color: #999;
  background: none;
  border: none;
  width: 35%;
  margin: 1rem 0;
  font-size: 2rem;
  display: inline-block;
}

.side-button.selected {
  color: #fff;
}

.filter-heading {
  background: none;
  border: none;
  padding: 0;
}

.filter-heading h3 {
  margin: .2rem 0;
  font-size: 1.8rem;
}

.filter-clear-button {
  background: none;
  border: none;
  padding: 0;
  font-size: 1.4rem;
}

.filter-list {
  color: #555;
  margin: .5rem 0;
}

.filter-group-items label {
  padding-left: 2.5em;
}

.filter-divider {
  margin: .5rem 0;
}

#reset button {
  background: none;
  border: none;
  padding: 0;
}

#small-print {
  max-width: 75rem;
  font-size: 1.1rem;
}

body {
  background-image: url("blackat-trim-flip.4354a50a.png"), url("blackat-background-thin.17dbc7b7.png");
  background-position: 2em 0, 0 0;
  background-repeat: no-repeat;
  background-size: 260px, auto;
  font-size: 1.4rem;
}

h1, h2, h3, h4, h5, select, input, button {
  font-family: Rajdhani, sans-serif;
}

a {
  color: #ff6e00;
}

header {
  padding-top: 2rem;
  padding-left: 4rem;
  position: relative;
}

main {
  grid-template-columns: 18em auto;
  column-gap: 3em;
  margin: 0 2em -100px;
  padding-top: 100px;
  display: grid;
  position: relative;
  top: -100px;
}

main.loading {
  justify-content: center;
  display: flex;
}

#sides {
  width: 15em;
  padding: 0 3rem;
  display: flex;
}

#sides button {
  width: 50%;
}

footer {
  justify-content: center;
  align-items: center;
  margin: 2rem;
  font-size: 1.1rem;
  display: flex;
}

#back-to-top {
  text-align: center;
  font-size: 1.6rem;
}

.mobile-only {
  display: none;
}

@media (max-width: 700px) {
  main, .mobile-only {
    display: block;
  }
}

#title {
  color: #fff;
  text-shadow: 0 0 2px #000;
}

#title h1 {
  margin-bottom: 0;
}

#title #subtitle {
  font-size: 1.1rem;
  font-weight: normal;
  position: relative;
  top: -.5rem;
}

.card-divider {
  clear: left;
  text-align: right;
  border-bottom: 2px solid #bbb;
  margin-bottom: 1rem;
  padding-bottom: .3em;
  padding-right: 5%;
  font-size: 2.5rem;
}

.card-tile {
  margin: .5rem;
  overflow: visible;
}

.card-tile img {
  aspect-ratio: 300 / 419;
  border-radius: 5%;
  width: 20rem;
  height: auto;
}

@media (max-width: 700px) {
  .card-tile img {
    width: 100%;
  }
}

@media (hover: hover) {
  .card-tile img:hover {
    z-index: 10;
    transform: scale(1.5);
    box-shadow: 0 0 6px #000;
  }

  @media (max-width: 700px) {
    :nth-child(3n+1 of .visible-card) img:hover {
      transform: scale(1.5)translateX(16%);
    }

    :nth-child(3n of .visible-card) img:hover {
      transform: scale(1.5)translateX(-16%);
    }
  }

  @media (max-width: 500px) {
    :nth-child(odd of .visible-card) img:hover {
      transform: scale(1.5)translateX(16%);
    }

    :nth-child(2n of .visible-card) img:hover {
      transform: scale(1.5)translateX(-16%);
    }
  }
}

@media (hover: none) {
  .card-tile.focused-card img {
    z-index: 10;
    transform: scale(2);
    box-shadow: 0 0 6px #000;
  }

  @media (max-width: 700px) {
    :nth-child(3n+1 of .visible-card).focused-card img {
      transform: scale(2)translateX(25%);
    }

    :nth-child(3n of .visible-card).focused-card img {
      transform: scale(2)translateX(-25%);
    }
  }

  @media (max-width: 500px) {
    :nth-child(odd of .visible-card).focused-card img {
      transform: scale(2)translateX(25%);
    }

    :nth-child(2n of .visible-card).focused-card img {
      transform: scale(2)translateX(-25%);
    }
  }
}

.card-section-container {
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 700px) {
  .card-section-container {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    display: grid;
  }
}

@media (max-width: 500px) {
  .card-section-container {
    grid-template-columns: 1fr 1fr;
  }
}

#no-cards {
  margin-top: 15rem;
}

.spinner {
  text-align: center;
  margin: 140px auto 0;
}

.spinner > div {
  background-color: #333;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  margin: 15px;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.spinner .bounce1 {
  animation-delay: -.32s;
}

.spinner .bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/*# sourceMappingURL=index.a967d234.css.map */
