.card-tile {
  margin: 0.5rem;
  overflow: visible;
}

.card-tile img {
  aspect-ratio: 300 / 419;
  width: 20rem;
  height: auto;
  border-radius: 5%;
}

@media (max-width: 700px) {
  .card-tile img {
    width: 100%;
  }
}
@media (hover: hover) {
  .card-tile img:hover {
    transform: scale(1.5, 1.5);
    z-index: 10;
    box-shadow: 0 0 6px #000;
  }

  @media (max-width: 700px) {
    :nth-child(3n+1 of .visible-card) img:hover {
      transform: scale(1.5, 1.5) translateX(16%);
    }
    :nth-child(3n of .visible-card) img:hover {
      transform: scale(1.5, 1.5) translateX(-16%);
    }
  }
  
  @media (max-width: 500px) {
    :nth-child(odd of .visible-card) img:hover {
      transform: scale(1.5, 1.5) translateX(16%);
    }
    :nth-child(even of .visible-card) img:hover {
      transform: scale(1.5, 1.5) translateX(-16%);
    }
  }
}

@media (hover: none) {
  .card-tile.focused-card img {
    transform: scale(2, 2);
    z-index: 10;
    box-shadow: 0 0 6px #000;
  }
  @media (max-width: 700px) {
    :nth-child(3n+1 of .visible-card).focused-card img {
      transform: scale(2, 2) translateX(25%);
    }
    :nth-child(3n of .visible-card).focused-card img {
      transform: scale(2, 2) translateX(-25%);
    }
  }
  
  @media (max-width: 500px) {
    :nth-child(odd of .visible-card).focused-card img {
      transform: scale(2, 2) translateX(25%);
    }
    :nth-child(even of .visible-card).focused-card img {
      transform: scale(2, 2) translateX(-25%);
    }
  }
}
